import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"nashi-poslugi"} />
		<Helmet>
			<title>
				Наші послуги | Surf's Up Camp
			</title>
			<meta name={"description"} content={"Відчуйте справжню пригоду в серфінгу"} />
			<meta property={"og:title"} content={"Наші послуги | Surf's Up Camp"} />
			<meta property={"og:description"} content={"Відчуйте справжню пригоду в серфінгу"} />
			<meta property={"og:image"} content={"https://smartwaysbin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://smartwaysbin.com/img/214353263462.jpeg"} />
			<meta name={"msapplication-TileColor"} content={"https://smartwaysbin.com/img/214353263462.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="400px"
			background="--color-light"
			display="flex"
			flex-direction="column"
			sm-min-height="auto"
		>
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				max-width="850px"
				color="--grey"
				margin="0px"
			>
				Що ми пропонуємо
			</Text>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline1"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
			>
				Наші послуги
			</Text>
			<Text
				margin="0px"
				width="80%"
				font="--base"
				color="--grey"
				md-width="100%"
				sm-margin="0px 0px 32px 0px"
			>
				У Surf's Up Camp ми прагнемо забезпечити комплексний досвід серфінгу, який задовольнить усі ваші потреби. Незалежно від того, чи ви новачок, який хоче зловити свою першу хвилю, чи досвідчений серфер, який прагне вдосконалити свої навички, наш асортимент послуг гарантує, що у вас є все необхідне для незабутньої пригоди.
			</Text>
		</Section>
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://smartwaysbin.com/img/8.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Уроки серфінгу
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Уроки для початківців: Призначені для новачків у серфінгу, наші уроки для початківців охоплюють основи веслування, стояння на дошці та катання на невеликих хвилях.
					<br />
					<br />
Проміжні уроки: Призначені для тих, хто має певний досвід серфінгу, ці уроки зосереджені на вдосконаленні техніки та опануванні більших хвиль.
					<br />
					<br />
Просунуті уроки: Для досвідчених серферів просунуті уроки пропонують персоналізовані тренування для вдосконалення маневрів і подолання складних умов серфінгу.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://smartwaysbin.com/img/9.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Прокат спорядження
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Дошки для серфінгу: Напрокат доступні різноманітні дошки, включаючи лонгборди, шортборди та пінопластові дошки, що гарантує вам ідеальну дошку для вашого рівня майстерності та хвильових умов.
					<br />
					<br />
Гідрокостюми: Ми пропонуємо високоякісні гідрокостюми всіх розмірів, щоб вам було комфортно і тепло під час серфінгу.
					<br />
					<br />
Аксесуари: Орендуйте необхідні аксесуари для серфінгу, такі як повідці, віск і ласти, щоб покращити свої враження від серфінгу.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://smartwaysbin.com/img/10.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Безпека та підтримка
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						
Рятувальні служби: Підготовлені рятувальники чергують під час усіх сесій серфінгу, щоб забезпечити безпеку та надати допомогу в разі потреби.
					<br />
					<br />
Перша допомога: Пункти першої медичної допомоги на місці та кваліфікований персонал, готовий надати допомогу при будь-яких незначних травмах або проблемах зі здоров'ям.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://smartwaysbin.com/img/11.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Серф-табори та клініки
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Табори вихідного дня: інтенсивні табори вихідного дня для тих, хто хоче зануритися в серфінг, з безліччю уроків і заходів.
					<br />
					<br />
Тижневі клініки: Розширені клініки, які пропонують більш глибоке занурення в техніку серфінгу, безпеку в океані та культуру серфінгу.
					<br />
					<br />
Приватні табори: Індивідуальні приватні табори для груп або окремих осіб, пристосовані до конкретних потреб і цілей.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Додаткові зручності
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Магазин для серфінгу: Магазин на території готелю пропонує широкий вибір спорядження для серфінгу, одягу та сувенірів на згадку про ваші пригоди на серфінгу.
					<br />
					<br />
Проживання: Для тих, хто відвідує багатоденні табори або клініки, пропонуються комфортні варіанти проживання.
					<br />
					<br />
Їжа та напої: Здорова їжа та закуски, які допоможуть вам залишатися енергійними протягом усього серфінгу.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://smartwaysbin.com/img/12.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://smartwaysbin.com/img/13.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Розпочніть свою серфінг-подорож сьогодні
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Приєднуйтесь до Surf's Up Camp і пориньте в атмосферу справжнього серфінгу. Завдяки нашим комплексним послугам та відданій команді, ми гарантуємо, що у вас є все необхідне, щоб осідлати хвилі та створити незабутні спогади. Зв'яжіться з нами зараз і давайте почнемо!
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});